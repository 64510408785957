import React from "react";
import axios from "axios";
import log from "../helper/log";

/** 

**/

const Basicform = () => (
  <>
    <label htmlFor="name">Name *</label>

    <input type="text" id="name" name="name" required />
    <label htmlFor="email">Email</label>
    <input type="email" id="email" name="email" />
    <label htmlFor="phone">Phone Number *</label>

    <input type="tel" id="phone" name="phone" required />
    <label htmlFor="comments">Comments</label>
    <textarea id="comments" name="comments"></textarea>
    <button type="submit">Send</button>
  </>
);

const ComplainForm = ({ state, handleRadio }) => {
  return (
    <>
      <div className="radio-pair" onChange={e => handleRadio(e)}>
        <p className="radio-text">
          Have you confirmed the person or business is an MFAA member? *
        </p>

        <div className="radio-container">
          <input
            type="radio"
            id="is-mfaa-member-yes"
            name="is_mfaa_member"
            value="Yes"
            className="form-radio"
          />

          <label htmlFor="is-mfaa-member-yes" className="option">
            <span></span>Yes
          </label>
        </div>
        <div className="radio-container">
          <input
            type="radio"
            id="is-mfaa-member-no"
            name="is_mfaa_member"
            value="No"
            className="form-radio"
          />

          <label htmlFor="is-mfaa-member-no" className="option">
            <span></span>No
          </label>
        </div>
      </div>

      {state.isMember === false && (
        <p className="form-item">
          In order for a complaint to be appropriately processed, the person or
          business about whom you wish to complain must be clearly identified
          and an MFAA member. If you unsure whether or not that entity is an
          MFAA member, please call the MFAA on{" "}
          <a href="tel:1300554817">1300 554 817</a> (within Australia) to
          confirm.
        </p>
      )}

      {state.isMember === true && (
        <div className="radio-pair" onChange={e => handleRadio(e)}>
          <p className="radio-text">
            Have you been through the member's Internal Dispute Resolution (IDR)
            process? *
          </p>

          <div className="radio-container">
            <input
              type="radio"
              id="internal-dispute-resolution-yes"
              name="internal_dispute_resolution"
              value="Yes"
              className="form-radio"
            />

            <label htmlFor="internal-dispute-resolution-yes" className="option">
              <span></span>Yes
            </label>
          </div>
          <div className="radio-container">
            <input
              type="radio"
              id="internal-dispute-resolution-no"
              name="internal_dispute_resolution"
              value="No"
              className="form-radio"
            />

            <label htmlFor="internal-dispute-resolution-no" className="option">
              <span></span>No
            </label>
          </div>
        </div>
      )}

      {state.isMember === true && state.disputeResolution === false && (
        <p className="form-item">
          In the first instance, try to resolve the complaint directly with the
          MFAA member. The MFAA requires its members to have internal dispute
          resolution (IDR) procedures in place to endeavour to resolve customer
          complaints within a reasonable time.
          <br />
          <br />
          Please contact the member.
        </p>
      )}

      {state.isMember === true && state.disputeResolution === true && (
        <div className="radio-pair" onChange={e => handleRadio(e)}>
          <p className="radio-text">Are you seeking monetary compensation? *</p>

          <div className="radio-container">
            <input
              type="radio"
              id="monetary-compensation-yes"
              name="monetary_compensation"
              value="Yes"
              className="form-radio"
            />

            <label htmlFor="monetary-compensation-yes" className="option">
              <span></span>Yes
            </label>
          </div>
          <div className="radio-container">
            <input
              type="radio"
              id="monetary-compensation-no"
              name="monetary_compensation"
              value="No"
              className="form-radio"
            />

            <label htmlFor="monetary-compensation-no" className="option">
              <span></span>No
            </label>
          </div>
          <div className="radio-container">
            <input
              type="radio"
              id="monetary-compensation-unsure"
              name="monetary_compensation"
              value="Unsure"
              className="form-radio"
            />

            <label htmlFor="monetary-compensation-unsure" className="option">
              <span></span>Unsure
            </label>
          </div>
        </div>
      )}

      {state.isMember === true &&
        state.disputeResolution === true &&
        state.monetaryCompensation === false && (
          <>
            <p className="form-item">
              The MFAA's Disciplinary Rules provide a mechanism to deal with
              complaints against an MFAA member who is alleged to have breached
              the MFAA Code of Practice. The Disciplinary Rules and Code of
              Practice can be viewed here (link).
              <br />
              <br />
              ​Under the Disciplinary Rules, if the MFAA deems that a breach of
              the MFAA Code of Practice may have occurred, it may refer the
              matter to an independent Investigating Officer. Following
              investigation, the Investigating Officer has discretion to refer
              the matter to the MFAA Tribunal or to close the matter if evidence
              is deemed insufficient. The MFAA Tribunal has the power to impose
              various sanctions on a member for misconduct, including suspension
              or expulsion from membership of the MFAA.
            </p>
            <Basicform />
          </>
        )}

      {state.isMember === true &&
        state.disputeResolution === true &&
        state.monetaryCompensation === true && (
          <>
            <p className="form-item">
              If your complaint remains unresolved after going through the
              member's Internal Dispute Resolution (IDR) procedures, you can
              refer the matter to the relevant External Dispute Resolution (EDR)
              scheme. The National Consumer Credit Protection Act 2009 requires
              that all Australian Credit licensees and their loan writing staff
              that deal in regulated credit must hold membership of an ASIC
              approved EDR scheme.
              <br />
              <br />
              The approved EDR schemes are the Credit and Investments Ombudsman
              (CIO) and the Financial Ombudsman Service (FOS)
              <br />
              <br />
              All MFAA members are required to be a member of, or be covered by,
              a membership of either CIO or FOS. CIO/FOS has the authority to
              order compensation to a consumer CIO/FOS may forward the complaint
              to the MFAA to take action under the MFAA's Disciplinary Rules if
              it considers there has been a case of misconduct.
            </p>
            <Basicform />
          </>
        )}
    </>
  );
};

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
    this.state = {
      selected: "",
      isMember: "",
      disputeResolution: "",
      monetaryCompensation: "",
      error: false,
      success: false,
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    let obj = {};
    const formData = new FormData(e.target);
    for (let key of formData.keys()) {
      obj[key] = formData.get(key);
    }
    axios
      .post("https://submit-form.com/gQ5hDn0x", {
        ...obj,
      })
      .then(response => {
        if (response?.status === 200) {
          this.setState({
            success: true,
          });
        } else {
          log.error(`Form error - ${response?.statusText}`);

          this.setState({
            error: true,
          });
        }
      })
      .catch(response => {
        log.error(`Form error - ${response}`);
        this.setState({
          error: true,
        });
      });
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({
      selected: e.target.value,
    });
  }

  handleRadio(e) {
    if (e.target.name === "is_mfaa_member") {
      this.setState({
        isMember: e.target.value === "Yes" ? true : false,
        disputeResolution: "",
        monetaryCompensation: "",
      });
    } else if (e.target.name === "internal_dispute_resolution") {
      this.setState({
        disputeResolution: e.target.value === "Yes" ? true : false,
        monetaryCompensation: "",
      });
    } else if (e.target.name === "monetary_compensation") {
      this.setState({
        monetaryCompensation: e.target.value === "Yes" ? true : false,
      });
    }
  }

  render() {
    return (
      <>
        {!this.state.error && !this.state.success && (
          <form
            // action="https://submit-form.com/ gQ5hDn0x"
            onSubmit={e => this.handleSubmit(e)}
          >
            <label htmlFor="enquiry">What is your enquiry about today?</label>
            <select
              required
              name="enquiry"
              id="enquiry"
              onChange={e => this.handleChange(e)}
              defaultValue={{ label: "Select", value: "" }}
            >
              <option value="">Select</option>
              <option value="General">General Enquiry</option>
              <option value="Feedback">Feedback for the MFAA</option>
              <option value="Compliment">
                Compliment your MFAA Finance Broker
              </option>
              <option value="Complain">
                Complain about an MFAA Finance Broker
              </option>
              <option value="Callback">Request a Call Back</option>
            </select>

            {this.state.selected && this.state.selected !== "Complain" && (
              <Basicform />
            )}
            {this.state.selected && this.state.selected === "Complain" && (
              <ComplainForm handleRadio={this.handleRadio} state={this.state} />
            )}
          </form>
        )}
        {this.state.success && (
          <p className="success">The form submitted successfully</p>
        )}

        {this.state.error && (
          <p className="error">
            There has been an error with your form submission. Please refresh
            and try again or contact us at info@mfaa.com.au
          </p>
        )}
      </>
    );
  }
}

export default ContactForm;
