import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import ContactDetails from "../components/contact-details";
import ContactForm from "../components/contact-form";
import AccordionModule from "../components/accordion-module";

export const query = graphql`
  query ContactPageQuery {
    page: allSanityContactPage(
      filter: { slug: { current: { eq: "contact-us" } } }
    ) {
      edges {
        node {
          pageSEO {
            metaDescription
            metaTitle
            metaImage {
              asset {
                _id
                altText
              }
              hotspot {
                height
                width
                x
                y
              }
            }
          }
          contactNumbers
          accordionModule {
            buttonText
            ...accordionModuleButtonLink
            heading
            items {
              heading
              _rawBody(resolveReferences: { maxDepth: 8 })
            }
          }
          officeAddress
          officeHours
          postalAddress
          title
          _id
        }
      }
    }
  }
`;

const Contact = props => {
  const { data } = props;
  const page = data.page.edges[0].node;
  return (
    <Layout className="contact">
      <SEO seo={page.pageSEO} title={page.title} />
      <ContactDetails page={page} />
      <section className="form">
        <h2 className="module-heading">Contact form</h2>
        <ContactForm />
      </section>
      {page.accordionModule && (
        <AccordionModule accordion={page.accordionModule} />
      )}
    </Layout>
  );
};

export default Contact;
