import React from "react";
import arrow from "../img/diagonal-black.svg";

const ContactDetails = ({ page }) => {
  return (
    <section className="contact-details">
      {page.officeAddress && (
        <div className="contact-detail-column">
          <h2 className="subtitle-one contact-detail-heading">
            Office address
          </h2>
          <p className="contact-detail-text">{page.officeAddress}</p>
          <a
            className="button ghost address-link subtitle-two add-underline"
            target="_blank"
            rel="noreferrer"
            href={`https://www.google.com/maps?saddr=My+Location&daddr=${page.officeAddress.replace(
              /(\n)/g,
              " "
            )}`}
          >
            Get directions
            <img src={arrow} alt="" />
          </a>
        </div>
      )}
      {(page.contactNumbers || page.officeHours) && (
        <div className="contact-detail-column">
          {page.contactNumbers && (
            <div className="contact-detail-row">
              <h2 className="subtitle-one contact-detail-heading">
                Contact numbers
              </h2>
              <p className="contact-detail-text">{page.contactNumbers}</p>
            </div>
          )}
          {page.officeHours && (
            <div className="contact-detail-row">
              <h2 className="subtitle-one contact-detail-heading">
                Office hours
              </h2>
              <p className="contact-detail-text">{page.officeHours}</p>
            </div>
          )}
        </div>
      )}
      {page.postalAddress && (
        <div className="contact-detail-column">
          <h2 className="subtitle-one contact-detail-heading">
            Postal address
          </h2>
          <p className="contact-detail-text">{page.postalAddress}</p>
        </div>
      )}
    </section>
  );
};

export default ContactDetails;
